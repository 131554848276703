import React, { useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";

const App = () => {
  const [userIp, setUserIp] = useState(null); // State to store user's IP

  const handleClick = async () => {
    try {
      // Send POST request to log click and get IP
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/log_click`
      );
      setUserIp(response.data.client_ip); // Update state with IP address
    } catch (error) {
      console.error("Error logging the button click:", error);
    }
  };

  return (
    <div>
      <h1>Button Click Logger</h1>
      <Button variant="outlined" color="primary" onClick={handleClick}>
        Log Button Click
      </Button>
      {userIp && <p>Your IP Address: {userIp}</p>}{" "}
      {/* Display the IP address */}
    </div>
  );
};

export default App;
